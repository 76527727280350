<template>
    <div style="margin-top: 20px">
        <el-tabs :tab-position="tabPosition" style="">
            <el-tab-pane label="积分赠送设置">
                <ji-fen-zeng-song-she-zhi-edit ref="jiFenZengSongSheZhiEdit"/>
            </el-tab-pane>
            <el-tab-pane label="积分抵扣设置">
                <el-form ref="form" :model="form" :rules="rules" class="x-edit">
                    <el-form-item label="是否开启" prop="shiFuKaiQi" class="line" style="border: none; width: 385px;">
                        <!--                <el-input v-model="form.shiFuKaiQi" size="small" maxlength="36" show-word-limit/>-->
                        <x-switch v-model="form.shiFuKaiQi" dictType="T_USE"/>
                    </el-form-item>
                    <!--            <el-form-item label="版本号" prop="banBenHao" >-->
                    <!--                <el-input v-model="form.banBenHao" size="small" maxlength="" show-word-limit/>-->
                    <!--            </el-form-item>-->
                    <el-form-item class="line" style="border: none;"></el-form-item>
                    <el-form-item v-if="form.shiFuKaiQi===isUse.ENABLE.key" label="营销方式:" label-width="width: 230px;"
                                  prop="yingXiaoFangShi" class="line" style="border: none; width: 385px;">
                        <el-radio v-model="form.yingXiaoFangShi" label="1">基于订单</el-radio>
                        <el-radio v-model="form.yingXiaoFangShi" label="2">基于商品</el-radio>
                    </el-form-item>

                    <el-form-item class="line" style="border: none; "></el-form-item>
                    <label v-if="form.shiFuKaiQi===isUse.ENABLE.key && form.yingXiaoFangShi===yingXiaoFS.JYDD.key"
                           style="font-size: 14px; line-height: 50px;background-color: #F5F7FA; width: 130px; text-align: center">
                        积分抵现比例:
                    </label>
                    <el-form-item v-if="form.shiFuKaiQi===isUse.ENABLE.key && form.yingXiaoFangShi===yingXiaoFS.JYDD.key" label="" label-width="auto"
                                  prop="diXianJiFen"
                                  style="border: none; width: 85px;">
                        <el-input style="width:85px;" v-model="form.diXianJiFen" size="small" maxlength="3"/>
                    </el-form-item>&nbsp;&nbsp;&nbsp;
                    <label v-if="form.shiFuKaiQi===isUse.ENABLE.key && form.yingXiaoFangShi===yingXiaoFS.JYDD.key"
                           style="font-size: 15px; line-height: 50px">积分=</label>&nbsp;
                    <el-form-item v-if="form.shiFuKaiQi===isUse.ENABLE.key && form.yingXiaoFangShi===yingXiaoFS.JYDD.key" label="" label-width="auto"
                                  prop="diXianJinE"
                                  style="border: none; width: 85px">
                        <el-input style="width:85px;" v-model="form.diXianJinE" disabled size="small" maxlength="8"/>
                    </el-form-item>
                    <br/>&nbsp;&nbsp;&nbsp;
                    <label v-if="form.shiFuKaiQi===isUse.ENABLE.key && form.yingXiaoFangShi===yingXiaoFS.JYDD.key"
                           style="font-size: 15px; line-height: 50px">元</label>

                    <el-form-item class="line" style="border: none;"></el-form-item>
                    <el-form-item v-if="form.shiFuKaiQi===isUse.ENABLE.key && form.yingXiaoFangShi===yingXiaoFS.JYDD.key" label="整单比例:" label-width="width: 230px;"
                                  prop="zhengDanBiLi" class="line" style="border: none; width: 385px;">
                        <el-input v-model="form.zhengDanBiLi" size="small" maxlength="" style="width:130px;">
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>

                    <div v-if="form.shiFuKaiQi===isUse.ENABLE.key && form.yingXiaoFangShi===yingXiaoFS.JYDD.key" style="margin-left: 100px;margin-top: 30px">
                        <p>如100元的订单，整单可抵现比例为10%：</p><br/>
                        <p >那么此订单中的10元将可使用积分进行抵扣，所需积分根据积分抵现比例进行换算</p>
                    </div>
                </el-form>
                <div style="margin-left: 100px">
                    <el-button type="primary" icon="el-icon-document-add" :loading="loading" @click="save">保 存
                    </el-button>
                </div>
            </el-tab-pane>
        </el-tabs>


    </div>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/JiFenDiKouSheZhi";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder, isMoney, isDigits} from '@/util/validate';
    import {deepCopy, deepMerge} from "@/util/objects";
    import {DICT_USE, YING_XIAO_FANG_SHI} from "@/util/constant";
    import JiFenZengSongSheZhiEdit from "@/view/hygl/jfdksz/JiFenZengSongSheZhiEdit";

    export default {
        name: 'JiFenDiKouSheZhiEdit',
        mixins: [XEditBase],
        components: {JiFenZengSongSheZhiEdit},
        data() {
            return {
                tabPosition: 'left',
                isUse: DICT_USE,
                yingXiaoFS:YING_XIAO_FANG_SHI,
                loading: false,
                form: deepCopy(this.defaultForm)
            }
        },
        computed: {
            edit() {
                return !(this.form.id === null || this.form.id === '');
            }
        },
        beforeCreate() {
            let {required, digits, money} = ruleBuilder;
            this.rules = {
                shiFuKaiQi: [], // 是否开启
                banBenHao: [], // 版本号
                diXianJiFen: [required(), digits()], // 抵现积分
                diXianJinE: [required(), money()], // 抵现金额
                zhengDanBiLi: [required(), digits()], // 整单比例
            }
            this.titlePrefix = '积分抵扣设置';
            this.defaultForm = {
                id: null,
                shiFuKaiQi: "", // 是否开启
                banBenHao: "", // 版本号
                diXianJiFen: "100", // 抵现积分
                diXianJinE: "1", // 抵现金额
                yingXiaoFangShi: "1", // 营销方式
                zhengDanBiLi: "10", // 整单比例
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                getUpdate().then(response => {
                    if (response.data && response.data.id) {
                        this.form = deepMerge(this.defaultForm, response.data);
                        // console.log("这是返回的数据", response.data)
                        // console.log("这是表单的数据", this.form)
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },
            async save() {
                try {
                    await this.$refs.form.validate();
                } catch (e) {
                    return;
                }
                if (!isDigits(this.form.diXianJiFen)) {
                    this.$message.warning("请检查积分格式是否正确");
                    return;
                }
                this.loading = true;
                let saveApi = this.edit ? update : add;
                const response = await saveApi(this.form).finally(() => {
                    this.refresh();
                });
                //打印提示信息
                this.$message.success(response.msg);
            }
        }
    }
</script>

<style scoped>
    p {
        color: rgb(153, 153, 153);
        font-size: 13px;
    }
</style>