<template>
    <div >
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
<!--            <el-form-item label="版本号" prop="banBenHao" >-->
<!--                <el-input v-model="form.banBenHao" size="small" maxlength="36" show-word-limit/>-->
<!--            </el-form-item>-->
            <el-form-item label="消费获得积分" prop="xiaoFeiZengSongKaiGuan" class="line cls" >
<!--                <el-input v-model="form.xiaoFeiZengSongKaiGuan" size="small" maxlength="36" show-word-limit/>-->
                <x-switch v-model="form.xiaoFeiZengSongKaiGuan" dictType="T_USE"/>
            </el-form-item>
            <el-form-item class="line" style="border: none; "></el-form-item>
            <el-form-item v-if="form.xiaoFeiZengSongKaiGuan===isUse.ENABLE.key" label="消费" prop="xiaoFeiJinE" class="cls">
                <el-input v-model="form.xiaoFeiJinE" size="small" maxlength="8" >
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>
            <el-form-item v-if="form.xiaoFeiZengSongKaiGuan===isUse.ENABLE.key" label="赠送" prop="xiaoFeiZengSongJiFen" class="cls">
                <el-input v-model="form.xiaoFeiZengSongJiFen" disabled size="small" maxlength="" >
                    <template slot="append">积分</template>
                </el-input>
            </el-form-item>
            <el-form-item class="line" style="border: none; "></el-form-item>
            <el-form-item label="充值赠送开关" prop="chongZhiZengSongKaiGuan" class="line cls">
<!--                <el-input v-model="form.chongZhiZengSongKaiGuan" size="small" maxlength="36" show-word-limit/>-->
                <x-switch v-model="form.chongZhiZengSongKaiGuan" dictType="T_USE"/>
            </el-form-item>
            <el-form-item class="line" style="border: none; "></el-form-item>
            <el-form-item v-if="form.chongZhiZengSongKaiGuan===isUse.ENABLE.key" label="充值" prop="chongZhiJinE" class="cls">
                <el-input v-model="form.chongZhiJinE" size="small" maxlength="8" >
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>
            <el-form-item v-if="form.chongZhiZengSongKaiGuan===isUse.ENABLE.key" label="赠送" prop="chongZhiZengSongJiFen" class="cls">
                <el-input v-model="form.chongZhiZengSongJiFen" disabled size="small" maxlength="" >
                    <template slot="append">积分</template>
                </el-input>
            </el-form-item>
        </el-form>
        <div style="margin-left: 100px">
            <el-button type="primary" icon="el-icon-document-add" :loading="loading" @click="save">保 存
            </el-button>
        </div>
    </div>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/JiFenZengSongSheZhi";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder, isMoney, isDigits, isPositiveEightTwo} from '@/util/validate';
    import {deepCopy, deepMerge} from "@/util/objects";
    import {DICT_USE} from "@/util/constant";

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required, money} = ruleBuilder;
            this.rules = {
                banBenHao: [], // 版本号
                xiaoFeiZengSongKaiGuan: [], // 消费赠送开关
                xiaoFeiJinE: [money(), required()], // 消费金额
                xiaoFeiZengSongJiFen: [], // 消费赠送积分
                chongZhiZengSongKaiGuan: [], // 充值赠送开关
                chongZhiJinE: [required(), money()], // 充值金额
                chongZhiZengSongJiFen: [], // 充值赠送积分
            }
            this.titlePrefix = '积分赠送设置';
            this.defaultForm = {
                id: null,
                banBenHao: "", // 版本号
                xiaoFeiZengSongKaiGuan: "", // 消费赠送开关
                xiaoFeiJinE: "", // 消费金额
                xiaoFeiZengSongJiFen: "1", // 消费赠送积分
                chongZhiZengSongKaiGuan: "", // 充值赠送开关
                chongZhiJinE: "", // 充值金额
                chongZhiZengSongJiFen: "1", // 充值赠送积分
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            return {
                isUse: DICT_USE,
                loading: false,
                form: deepCopy(this.defaultForm)
            }
        },
        computed: {
            edit() {
                return !(this.form.id === null || this.form.id === '');
            }
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                getUpdate().then(response => {
                    if (response.data && response.data.id) {
                        this.form = deepMerge(this.defaultForm, response.data);
                        // console.log("这是返回的数据", response.data)
                        // console.log("这是表单的数据", this.form)
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },
            async save() {
                try {
                    await this.$refs.form.validate();
                } catch (e) {
                    return;
                }
                if (!/^\d+(\.\d{1,2})?$/.test(this.form.xiaoFeiJinE) && this.form.xiaoFeiZengSongKaiGuan===this.isUse.ENABLE.key){
                    this.$message.warning("消费金额格式不正确")
                    return ;
                }
                if (!/^\d+(\.\d{1,2})?$/.test(this.form.chongZhiJinE) && this.form.chongZhiZengSongKaiGuan===this.isUse.ENABLE.key){
                    this.$message.warning("充值金额格式不正确")
                    return ;
                }
                this.loading = true;
                let saveApi = this.edit ? update : add;
                const response = await saveApi(this.form).finally(() => {
                    this.refresh();
                });
                //打印提示信息
                this.$message.success(response.msg);
            }
        }
    }
</script>

<style scoped>
    .cls{
        border: none;
        width: 300px
    }
</style>